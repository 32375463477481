.edit-metadata-dialog .MuiDialog-paper {
  background: white;
  box-shadow: 0px 3.5px 5.5px rgba(0, 0, 0, 0.02);
  border-radius: 35px;
  overflow: visible;
  max-width: 652px;
  width: 100%;
  margin: 16px;
  padding-left: 20px;
}

.edit-metadata-dialog-container {
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.edit-metadata-dialog-container .close-button {
  position: absolute !important;
  top: 22px !important;
  right:  22px !important;
  width: 30px !important;
  height: 30px !important;
  margin: 0 !important;
  padding: 2 !important;
  color: #000 !important;
  border-radius: 50% !important;
  background: #242426;
  border: 2px solid #FFFFFF;
}

.edit-metadata-dialog-container .close-button .MuiSvgIcon-root {
  width: 18px;
  height: 18px;
  fill: #FFFFFF;
}

.edit-metadata-dialog-container .dialog-content-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.edit-metadata-dialog-container .title-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 24px;
}
.edit-metadata-dialog-container .dialog-title {
  padding: 16px 24px 4px;

}

.edit-metadata-dialog-container .dialog-title h2 {
  color: rgba(1, 99, 122, 1);
  padding: 0 !important;
  margin: 30px 0 8px 0 !important;
  font-weight: 700;
  font-size: 36px;
  line-height: 46px;
  text-align: center;
  vertical-align: middle;
  font-family: var(--font-family);

}

.edit-metadata-dialog-container .dialog-subtitle {
  text-align: center;
  color: rgba(36, 36, 38, 1);
  margin: 0 !important;
  max-width: 80%;
  font-weight: 400;
  font-size: 18px;
  line-height: 100%;
  letter-spacing: 0%;
  vertical-align: middle;
  font-family: var(--font-family);
}

.edit-metadata-dialog-container .form-fields-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-right: 20px;
}

.edit-metadata-dialog-container .field-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.edit-metadata-dialog-container .field-label {
  margin: 0 !important;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: rgba(68, 68, 70, 1);
  font-family: var(--font-family);
}

.edit-metadata-dialog-container .text-field {
  width: 327px;
  font-family: var(--font-family);
}

.edit-metadata-dialog-container .text-field .MuiOutlinedInput-root {
  border-radius: 8px;
  padding: 0px !important;
}

.edit-metadata-dialog-container .text-field .MuiOutlinedInput-notchedOutline {
  border: 1px solid #E0E0E0;
}

.edit-metadata-dialog-container .text-field input, .edit-metadata-dialog-container .text-field textarea {
  font-size: 16px !important;
  color: #333333;
  padding: 12px;
}

.edit-metadata-dialog-container .text-field input::placeholder, .edit-metadata-dialog-container .text-field textarea::placeholder {
  color: rgba(124, 124, 128, 1);
  font-family: var(--font-family);
}

.edit-metadata-dialog-container .switch-container {
  display: flex;
  align-items: center;
  gap: 20px;
  margin: 8px 0 20px;
  flex-direction: row;
  justify-content: flex-end;
  font-family: var(--font-family);
}

.edit-metadata-dialog-container .switch-label {
  color: #333333;
  margin-right: auto !important;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;
  letter-spacing: 0%;
  vertical-align: middle;

}

/* Custom switch styling to match the image */
.edit-metadata-dialog-container .MuiSwitch-root {
  width: 36px !important;
  height: 32px !important;
  padding: 0 !important;
  overflow: visible;
}

.edit-metadata-dialog-container .MuiSwitch-switchBase {
  padding: 6px !important;
  top: 0 !important;
  left: -6px !important;
  transition: all 0.2s !important;
}

.edit-metadata-dialog-container .MuiSwitch-switchBase.Mui-checked {
  transform: translateX(18px) !important;
}

.edit-metadata-dialog-container .MuiSwitch-track {
  background-color: #E6E6E6 !important;
  opacity: 1 !important;
  border-radius: 16px !important;
  height: 12px !important;
  width: 36px !important;
  margin: 0 !important;
  position: absolute;
  top: 35%;
}

.edit-metadata-dialog-container .MuiSwitch-thumb {
  width: 20px !important;
  height: 20px !important;
  background: white !important;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2) !important;
  border-radius: 50% !important;
}

.edit-metadata-dialog-container .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
  background: #026E85 !important;
  opacity: 1 !important;
}


.edit-metadata-dialog-container .MuiDialogActions-root {
  padding: 0 !important;
  justify-content: flex-end !important;
}


.edit-metadata-dialog-container .update-button {
  margin-right: 20px;
  padding: 8px 24px !important;
  background: linear-gradient(270deg, #015366 0%, #01A5CC 100%);

  border-radius: 25px !important;
  color: white !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  text-transform: none !important;
  box-shadow: none !important;
}