@font-face {
  font-family: roboto_medium;
  src: url(assets/Roboto-Medium.ttf);
}

@font-face {
  font-family: roboto_lightitalic;
  src: url(assets/Roboto-LightItalic.ttf);
}

@font-face {
  font-family: roboto_bolditalic;
  src: url(assets/Roboto-BoldItalic.ttf);
}

@font-face {
  font-family: roboto_italic;
  src: url(assets/Roboto-Italic.ttf);
}

@font-face {
  font-family: roboto_regular;
  src: url(assets/Roboto-Regular.ttf);
}

@font-face {
  font-family: roboto_mediumitalic;
  src: url(assets/Roboto-MediumItalic.ttf);
}

@font-face {
  font-family: 'roboto_light';
  src: url(assets/Roboto-Light.ttf);
}


html {
  height: 100%;
  width: 100%;
}

body {
  font-family: 'Barlow', sans-serif;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  -webkit-font-smoothing: antialiased;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  background-color: #f7f7f7;
}


.traceblocData {
  /* background-color: #ffffff; */
  display: flex;
  flex: 1 1 0;
  height: 100%;
  min-width: 1400px;
}

.main {
  height: 100%;
  min-height: 100vh;
  align-self: stretch;
  padding-top: 44px;
  flex-grow: 1;
  transition: all 0.3s ease;
}

.sidebar {
  width: 100%;
  min-width: 65px;
  max-width: 184px;
  background-color: white;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 100;
}



.gradient-button {
  /* position: relative; */
  /* margin-left: auto; */
  /* margin-right: 36px; */
  /* align-self: flex-end; */
  min-width: 124px;
  text-align: center;
  /* top: -20px; */
}

.gradient-button button {
  min-width: 124px;
  height: 32px;
  border-radius: 1px;
  color: #ffffff;
  font-size: 12px;
  font-weight: 500;
  font-style: italic;
  text-transform: inherit;
  border-radius: 5px;
  font-family: 'Barlow', sans-serif;
  background-color: #3576a7;
  background-image: url('./assets/icon-hover.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.gradient-button button.MuiButton-root.Mui-disabled {
  color: #ffffff99;
}

.gradient-button button:hover {
  background-color: #3576a7;
  color: #fff;
  background-image: url('./assets/icon-hover.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.9;
}



.bold-700-12 {
  color: #3576a7;
  font-size: 12px;
  font-weight: 700;
  font-style: italic;
  font-family: 'Barlow', sans-serif;
}

.bold-400-12 {
  color: #3576a7;
  font-size: 12px;
  font-weight: 400;
  font-style: italic;
  
}
.bold-300-12 {
  color: #3576a7;
  font-size: 12px;
  font-weight: 300;
  font-style: italic;
  font-family: 'Barlow', sans-serif;
}
.bold-300-light-12 {
  color: #3576a7;
  font-size: 12px;
  font-weight: 300;
  font-style: italic;
  font-family: 'Barlow', sans-serif;
}

.title-italic-24 {
  color: #3576a7;
  font-size: 24px;
  font-weight: 700;
  font-style: italic;
  font-family: 'Barlow', sans-serif;
  margin-left: 40px;
}



.pulse-blob {
  background: var(--blue-status);
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  margin: 5px;
  height: 15px;
  width: 15px;
  transform: scale(1);

}


/* 
//////\\\\\\\\\\\\
Blue animation 
//////\\\\\\\\\\\\
*/
.pulse-blob.blue {
  background: rgba(52, 172, 224, 1);
  box-shadow: 0 0 0 0 var(--blue-status);
  animation: pulse-blue 1.5s infinite;
}
@keyframes pulse-blue {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 var(--blue-status);
  }
  
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(52, 172, 224, 0);
  }
  
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(52, 172, 224, 0);
  }
}

/* 
/////////\\\\\\\
////////\\\\\\\\
 */

 .MuiAlert-root {
   border-radius: 5px;
 }

 .MuiAlert-filledSuccess {
  background-color: #5DD39E;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
 }

 .MuiAlert-filledSuccess .MuiAlert-action button {
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
 }

 .MuiAlert-filledSuccess .MuiButton-label {
   color: #fff;
 }


 .MuiAlert-standardError .MuiAlert-message {
  color: rgb(237, 247, 250);
  font-size: 10px;
  font-family: "Barlow", sans-serif;
  font-weight: 500;
  font-style: italic;
  text-align: center;
}

.MuiAlert-standardWarning .MuiAlert-message {
  color: grey;
  font-size: 10px;
  font-family: "Barlow", sans-serif;
  font-weight: 500;
  font-style: italic;
  text-align: center;
}

 .MuiAlert-standardError {
  background-color: rgb(227, 16, 16);
  opacity: 0.5;
 }

 .MuiAlert-message {
  font-family: 'Barlow', sans-serif;
  color: #EDF7FA;
  font-size: 12px;
  line-height: 14px;
  font-style: italic;
  font-weight: 400;
  text-align: center;
  width: 100%;
  padding: 12px 0;
 }




 /* CSS for success and error mesages inside a component */


 .inner-message {
  width: 100%;
 }
.inner-message .MuiAlert-root {
  border-radius: 5px;
  font-family: 'Barlow', sans-serif;
}

.inner-message .MuiAlert-filledSuccess {
 background-color: #5DD39E;
 font-size: 10px;
 font-style: italic;
 font-weight: 400;
 line-height: 11px;
 text-align: center;
 width: 100%;
}

.inner-message .MuiAlert-filledSuccess .MuiAlert-action button {
 font-size: 12px;
 line-height: 14px;
 font-weight: 400;
}

.inner-message .MuiAlert-filledSuccess .MuiButton-label {
  color: #fff;
}


.inner-message .MuiAlert-standardError .MuiAlert-message {
 color: #edf7fa;
 font-size: 10px;
 font-style: italic;
 font-weight: 400;
 line-height: 11px;
 text-align: center;
 width: 100%;
 padding: 2px 0;
}

.inner-message .MuiAlert-standardError {
 background-color: rgb(227, 16, 16);
 opacity: 0.5;
}


.tour {
  text-align: left;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  font-style: italic;
  letter-spacing: 0px;
  color: #212529;
}