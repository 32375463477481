
:root {
  --primary: #3576a7;
  --white: #ffffff;
  --textPrimary: #707070;
  --blue-status: rgba(75, 189, 204, 1);
  --orange-status: rgba(255, 167, 0, 1);
  --red-status: rgba(227, 16, 16, 1);
  --grey-status: #e6e6e6;
  --crack: #410E52;
  --inclusion: #FF9C29;
  --porosity: #7DD0A2;



  --primary-color: rgba(1, 132, 163, 1);
  --text-color: #4B5563;
  --border-color: #7C7C80;
  --light-bg: #FFFFFF;
  --light-gray: #E5E7EB;
  --icon-color: #9CA3AF;
  --icon-bg: #F3F4F6;
  --icon-bg-hover: #E5E7EB;
  
  /* Tag colors */
  --tag-red-bg: rgba(255, 45, 85, 0.05);
  --tag-red-color: #EF4444;
  --tag-blue-bg: rgba(0, 122, 255, 0.05);
  --tag-blue-color: #3B82F6;
  --tag-green-bg: #D1FAE5;
  --tag-green-color: #10B981;
  --tag-yellow-bg: rgba(255, 179, 35, 0.05);
  --tag-yellow-color: rgba(234, 154, 4, 1);
  --tag-purple-bg: rgba(79, 70, 229, 0.1);
  --tag-purple-color: rgba(88, 86, 214, 1);
  --tag-bluish-bg: rgba(204, 237, 245, 0.5);
  --tag-bluish-color: rgba(1, 132, 163, 1);
  /* Font settings */
  --font-family: 'Barlow', sans-serif;
  --font-size-normal: 16px;
  --font-size-small: 14px;
  --font-size-title: 24px;

}

html {
  scroll-behavior: smooth;
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Barlow', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  
}

.m-auto { margin: auto; }
.mx-auto { margin-left: auto; margin-right: auto; }
.my-auto { margin-top: auto; margin-bottom: auto; }

.m-1 { margin: 2px; }
.m-2 { margin: 4px; }
.m-4 { margin: 8px; }

.mt-1 { margin-top: 2px; }
.mt-2 { margin-top: 4px; }
.mt-4 { margin-top: 8px; }
.mt-8 { margin-top: 16px; }

.mb-1 { margin-bottom: 2px; }
.mb-2 { margin-bottom: 4px; }
.mb-4 { margin-bottom: 8px; }
.mb-8 { margin-bottom: 16px; }

.ml-1 { margin-left: 2px; }
.ml-2 { margin-left: 4px; }
.ml-4 { margin-left: 8px; }

.mr-1 { margin-right: 2px; }
.mr-2 { margin-right: 4px; }
.mr-4 { margin-right: 8px; }


.mx-1 { margin-left: 2px; margin-right: 2px;}
.my-1 { margin-top: 2px; margin-bottom: 2px;}

.mx-2 { margin-left: 4px; margin-right: 4px;}
.my-2 { margin-top: 4px; margin-bottom: 4px;}

.mx-4 { margin-left: 8px; margin-right: 8px;}
.my-4 { margin-top: 8px; margin-bottom: 8px;}

.mx-8 { margin-left: 16px; margin-right: 16px;}
.my-8 { margin-top: 16px; margin-bottom: 16px;}




.barlow-thin {
  font-family: "Barlow", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.barlow-extralight {
  font-family: "Barlow", sans-serif;
  font-weight: 200;
  font-style: normal;
}

.barlow-light {
  font-family: "Barlow", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.barlow-regular {
  font-family: "Barlow", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.barlow-medium {
  font-family: "Barlow", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.barlow-semibold {
  font-family: "Barlow", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.barlow-bold {
  font-family: "Barlow", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.barlow-extrabold {
  font-family: "Barlow", sans-serif;
  font-weight: 800;
  font-style: normal;
}

.barlow-black {
  font-family: "Barlow", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.barlow-thin-italic {
  font-family: "Barlow", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.barlow-extralight-italic {
  font-family: "Barlow", sans-serif;
  font-weight: 200;
  font-style: italic;
}

.barlow-light-italic {
  font-family: "Barlow", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.barlow-regular-italic {
  font-family: "Barlow", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.barlow-medium-italic {
  font-family: "Barlow", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.barlow-semibold-italic {
  font-family: "Barlow", sans-serif;
  font-weight: 600;
  font-style: italic;
}

.barlow-bold-italic {
  font-family: "Barlow", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.barlow-extrabold-italic {
  font-family: "Barlow", sans-serif;
  font-weight: 800;
  font-style: italic;
}

.barlow-black-italic {
  font-family: "Barlow", sans-serif;
  font-weight: 900;
  font-style: italic;
}




/* ::-webkit-scrollbar {
  width: 10px;
} */

/* Track */
/* ::-webkit-scrollbar-track {
  background: #f1f1f1; 
  border-radius: 5px;
} */
 
/* Handle */
/* ::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
} */

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
  background: #555; 
} */

