/* MetaDataCard styles */

.metadata-card {
  background-color: var(--light-bg);
  border-radius: 15px;
  box-shadow: 0px 3.5px 5.5px rgba(0, 0, 0, 0.02);
  padding: 21px 27px;
  width: 32%;
  max-width: 416px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border: 0.5px solid var(--border-color);
  flex: none;
  order: 1;
  flex-grow: 1;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.metadata-card:hover, 
.metadata-card:focus-within {
  transform: translateY(-5px);
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1);
}

.metadata-card .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.metadata-card .tags {
  display: flex;
  gap: 8px;
}

.metadata-card .tag {
  font-size: var(--font-size-small);
  font-weight: 500;
  padding: 4px 12px;
  border-radius: 20px;
  display: inline-block;
}

.metadata-card .tag--red {
  background-color: var(--tag-red-bg);
  color: var(--tag-red-color);
}

.metadata-card .tag--blue {
  background-color: var(--tag-blue-bg);
  color: var(--tag-blue-color);
}

.metadata-card .tag--bluish {
  background-color: var(--tag-bluish-bg);
  color: var(--tag-bluish-color);
}

.metadata-card .tag--green {
  background-color: var(--tag-green-bg);
  color: var(--tag-green-color);
}

.metadata-card .tag--yellow {
  background-color: var(--tag-yellow-bg);
  color: var(--tag-yellow-color);
}

.metadata-card .tag--purple {
  background-color: var(--tag-purple-bg);
  color: var(--tag-purple-color);
}

.metadata-card .icons {
  display: flex;
  gap: 8px;
}

.metadata-card .info-icon {
  color: var(--icon-color);
  font-size: 20px;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.metadata-card .pencil-icon {
  color: var(--icon-color);
  font-size: 20px;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.metadata-card .info-icon:hover,
.metadata-card .info-icon:focus,
.metadata-card .pencil-icon:hover,
.metadata-card .pencil-icon:focus {
  filter: brightness(0.8);
}


.metadata-card .title {
  padding: 0;
  margin: 0;
  font-family: var(--font-family);
  font-size: var(--font-size-title);
  line-height: 34px;
  font-weight: 700;
  color: var(--primary-color);
  text-align: left;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  
}

.metadata-card .description {
  font-family: var(--font-family);
  padding: 0;
  margin: 0;
  color: var(--text-color);
  font-size: var(--font-size-normal);
  margin-bottom: 24px;
  text-align: left;
  width: 100%;
  line-height: 1.5;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.metadata-card .details {
  font-family: var(--font-family);
  font-weight: 400;
  color: var(--text-color);
  font-size: var(--font-size-normal);
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.metadata-card .detail {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: var(--font-size-normal);
}

.metadata-card .metadata-id {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.metadata-card .metadata-id-icon {
  color: #6B7280;
  cursor: pointer;
}

.metadata-card .metadata-id-icon:hover {
  color: #4B5563;
}

.metadata-card .metadata-id-icon:focus {
  outline: 2px solid var(--primary-color);
  outline-offset: 2px;
}

.metadata-card .hr {
  border: none;
  border-top: 1px solid var(--light-gray);
  width: 100%;
}

.metadata-card .client {
  display: flex;
  gap: 8px;
  align-items: center;
}

.metadata-card .client-tag {
  font-size: var(--font-size-small);
  font-weight: 500;
  padding: 2px 3px 3px 2px;
  border-radius: 5px;
  display: inline-block;
  gap: 10px;
  border-radius: 5px;
  border-width: 1px;
  padding-top: 2px;
  padding-right: 3px;
  padding-bottom: 2px;
  padding-left: 3px;
}

.metadata-card .client-tag--red {
  background-color: var(--light-bg);
  color: var(--tag-red-color);
  border: 1px solid var(--tag-red-color);
}

.metadata-card .client-tag--green {
  background-color: var(--light-bg);
  color: var(--tag-green-color);
  border: 1px solid var(--tag-green-color);
}

.metadata-card .client-tag--yellow {
  background-color: var(--light-bg);
  color: #EAB308;
  border: 1px solid #EAB308;
}

.metadata-card .client-tag--orange {
  background-color: var(--light-bg);
  color: #EA580C;
  border: 1px solid #EA580C;
}

.metadata-card .client-tag--gray {
  background-color: var(--light-bg);
  color: #6B7280;
  border: 1px solid #6B7280;
}

/* Tooltip Custom Styles */
.custom-tooltip {
  background: var(--grey-04, rgba(68, 68, 70, 1));

  color: var(--text-color) !important;
  border-radius: 8px !important;
  padding: 0 !important;
  max-width: none !important;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1) !important;
  border: 1px solid var(--border-color) !important;
}

.custom-arrow {
  color: var(--grey-04, rgba(68, 68, 70, 1));
}

.info-tooltip {
  min-width: 300px;
  padding: 0;
}

.tooltip-header {
  background-color: rgba(68, 68, 70, 1);
  color: white;
  padding: 12px 16px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.tooltip-title {
  font-weight: 600;
  font-size: var(--font-family);
}

.tooltip-content {
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.tooltip-row {
  display: flex;
  justify-content: space-between;
}

.tooltip-label {
  color: rgba(255, 255, 255, 1);
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0%;
  text-align: center;
}

.tooltip-value {
  color: rgba(255, 255, 255, 1);
  font-family: var(--font-family);
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0%;
  text-align: center;
} 