.company-details-container {
  margin-bottom: 8px;
  margin-top: 16px;
}

.section-title {
  color: rgba(53, 118, 167, 1);
  font-weight: 400;
  font-style: italic;
  font-family: 'Roboto', Arial;
  font-size: 14px;
  margin-bottom: 16px;
}

.admin-card {
  transition: background-color 0.2s ease;
  border-radius: 8px;
  padding: 12px;
}

.admin-card:hover {
  background-color: #f1f5f9;
} 