.table-global-dataset-list-container {
    padding-bottom: 8px;
    max-height: 800px;
    overflow: scroll;
}
.table-global-dataset-list-container::-webkit-scrollbar {
    width: 3px;
    height: 3px;
}


.table-global-dataset-list-container .table {
    background-color: #ffffff;
    border-collapse: separate;
    border-spacing: 0 5px;
}

.table-global-dataset-list-container .table thead > tr > th {
    color: rgba(53, 118, 167, 1);
    font-weight: 400;
    font-style: italic;
    font-family: 'Roboto', Arial;
    font-size: 12px;
    border-bottom: 0px;
    padding: 2px 8px;
    line-height: 1rem;
}

.table-global-dataset-list-container .table th:last-child {
    text-align: right;
}

.table-global-dataset-list-container .table .empty-td {
    border: 0px;
}


.table-global-dataset-list-container .table td {
    padding: 2px 8px;
}

.table-global-dataset-list-container .table td:first-child {
    border-left-width: 1px;
    border-radius: 5px 0 0 5px;
}
.table-global-dataset-list-container .table td:last-child {
    border-right-width: 1px;
    border-radius: 0 5px 5px 0;
    text-align: right;
}


.table-global-dataset-list-container .table tbody > .MuiTableRow-root:nth-child(odd) {
    background-color: #f8fafc;
}

.table-global-dataset-list-container .table tbody > .MuiTableRow-root:nth-child(odd) td {
    padding: 8px;
}

.table-global-dataset-list-container .table tbody > .MuiTableRow-root:nth-child(even) {
    background-color: #ffffff;
}

.table-global-dataset-list-container .table tbody > .MuiTableRow-root:nth-child(even) td {
    padding: 8px;
}

.table-global-dataset-list-container .table tbody > .MuiTableRow-root:hover {
    background-color: rgba(53, 118, 167, 0.1);
}

.table-global-dataset-list-container .table .MuiTableCell-stickyHeader {
    background-color: white;
}

.table-global-dataset-list-container .table tbody > tr > td {
    color: var(--textPrimary);
    font-weight: 500;
    font-style: italic;
    font-size: 12px;
    font-family: 'Roboto', Arial;
    border-width: 1px 0;
    border-style: solid;
    border-color: rgba(230, 230, 230, 1);
    /* cursor: pointer; */
}

.table-global-dataset-list-container .table .sort-label {
    color: rgba(53, 118, 167, 1);
}

.table-global-dataset-list-container .table .sort-icon {
    color: rgba(53, 118, 167, 1);
    opacity: 0.7;
}

.table-global-dataset-list-container .table .sort-icon-active {
    color: rgba(53, 118, 167, 1);
    opacity: 1;
    font-weight: bold;
}

.table-global-dataset-list-container .table .sort-icon-active > svg > path {
    color: rgba(53, 118, 167, 1);
}

.table-global-dataset-list-container .table .icon-button {
    padding: 4px;
    color: rgba(53, 118, 167, 1);

}


.table-global-dataset-list-container .table .icon {
    width: 16px;
    height: 16px;
}

.table-global-dataset-list-container .table .avatar-small {
    width: 20px;
    height: 20px;
    color: rgba(112, 112, 112, 1);
    font-size: 8px;
    font-weight: 400;
    font-style: italic;
    font-family: 'Roboto', Arial;
    background-color: rgba(247, 247, 247, 1);
    border: 1px solid rgba(247, 247, 247, 1);

}

.table-global-dataset-list-container .table .rating-stars {
    color: rgba(53, 118, 167, 1);
}



.table-global-dataset-list-container .table .dataset-type {
    font-weight: 600;
    font-size: 14px;
    color: var(--textPrimary);
    margin-bottom: 4px;
    font-style: normal;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 230px;
}

.table-global-dataset-list-container .table .dataset-description {
    font-size: 12px;
    color: #6B7280;
    font-style: normal;
    font-weight: 400;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 230px;
    cursor: text;
}

.table-global-dataset-list-container .table .dataset-description:hover {
    background-color: rgba(0, 0, 0, 0.04);
}

.table-global-dataset-list-container .table .MuiTextField-root {
    margin: 0;
    padding: 0;
}

.table-global-dataset-list-container .table .MuiInputBase-root {
    padding: 4px 8px;
}

.table-global-dataset-list-container .table .dataset-description-container {
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
}

.table-global-dataset-list-container .table .dataset-description-input {
  margin-top: 4px;
}

.table-global-dataset-list-container .table .dataset-description-input .MuiInputBase-input {
  font-size: 12px;
  padding: 4px 8px;
  color: #6B7280;
}

.table-global-dataset-list-container .table .gpu-label {
  font-size: 12px;
  color: #6B7280;
  font-style: normal;
  font-weight: 400;
}

.table-global-dataset-list-container .table .gpu-loading {
  margin-left: 8px;
  color: rgba(53, 118, 167, 1);
}

.table-global-dataset-list-container .table .gpu-error {
  display: block;
  margin-top: 4px;
  font-size: 10px;
}

/* Prevent the switch from being clickable when disabled */
.table-global-dataset-list-container .table .Mui-disabled {
  pointer-events: none;
}

.error-container {
  margin: 12px 0;
  width: 100%;
}


.error-message {
  text-align: left;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  font-style: italic;
  letter-spacing: 0px;
  color: rgb(227, 16, 16);
  background-color: white;
  opacity: 1;
  padding: 8px;
  font-family: 'Roboto';
}
